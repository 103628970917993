import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import "./article.css";
import axios from "axios";
import logo from "../media/logo/logo.png";
import { useParams } from "react-router-dom";
import desc from "../media/images/desc.png";
import { ArticleViewer } from "./articleViewer";
import { backendUrl } from "./api";
function Articles() {
  const [data, setData] = useState([]);
  const { connectionTitle } = useParams();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    // We use axios to send a GET request to the php file to fetch the data
    axios
      .get(`${backendUrl}/readArticle.php/?title=${connectionTitle}`)
      .then((response) => {
        // We handle the response from the php file

        // We update the result state variable with the response data
        setData(response.data);
        console.log(data);
      })
      .catch((error) => {
        // We handle the error from the php file
        console.error(error);
      });
  }, [connectionTitle]);
  // Access the item id from the URL params

  return <div className="container" id="articleDiv"></div>;
}

export default Articles;
