import Slider from "react-slick";
import React, { useState } from "react";
import "./teamCar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { clientAll } from "./clientAll";
import axios from "axios";
import { Image } from "react-bootstrap";
import { GrNext, GrPrevious } from "react-icons/gr";

import "./teamCar.css";

const TeamDisplay = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Mainsettings = {
    dots: true,
    speed: 200,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 3,
          slidesPerRow: 1,
          slidesToShow: 1,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <div className="content">
      <h4>CLIENTS</h4>{" "}
      <div className="container">
        <Slider {...Mainsettings}>
          {clientAll.map((data) => (
            <div key={data.Name}>
              <div className="img-body">
                <Image
                  onClick={handleShow}
                  fluid
                  style={{ height: "120px", maxWidth: "275px" }}
                  variant="top"
                  id="clientCarPhoto"
                  src={data.photo}
                  key={data.Name}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TeamDisplay;
