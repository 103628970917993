import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import "./teamCar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TeamMembers } from "./teamMembers";
import axios from "axios";
import {
  Card,
  Row,
  Col,
  Image,
  Carousel,
  Modal,
  Button,
} from "react-bootstrap";
import { GrNext, GrPrevious } from "react-icons/gr";

import "./teamCar.css";
import lnGr from "../media/icons/lnGr.png";
import { Profile } from "./team";
import { backendUrl } from "./api";
const TeamDisplay = () => {
  const [team, setTeam] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [member, setMember] = useState([]);
  const [clickIndex, setClickIndex] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();

  useEffect(() => {
    getTeam();
  }, []);
  function getTeam() {
    axios.get(`${backendUrl}/team/getTeam.php`).then(function (response) {
      setTeam(response.data);
    });
  }
  const searchTeam = (name) => {
    axios
      .get(`${backendUrl}/team/teamSearch.php?name=${name}`)
      .then(function (response) {
        setMember(response.data);
        console.log(team);
      });
  };
  const Mainsettings = {
    dots: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 900,
          dots: false,
        },
      },
    ],
  };
  const ModalSettings = {
    dots: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: (
      <div>
        <div className="next-slick-arrow" id="carControls">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            id="controlSvg"
          >
            {" "}
            <path
              stroke="#1AA99F"
              d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z"
            />
          </svg>
        </div>
      </div>
    ),

    prevArrow: (
      <div>
        <div className="next-slick-arrow rotate-180" id="carControls">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="#1AA99F"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" />
          </svg>
        </div>
      </div>
    ),
  };
  return (
    <>
      <div className="teamContent">
        <div className="container">
          <Slider {...Mainsettings}>
            {TeamMembers.map((data, index) => (
              <div id="teamCarCard" key={index}>
                <div
                  className="img-body"
                  onClick={() => {
                    searchTeam(data.Name);
                    setClickIndex(index);
                  }}
                >
                  <Image
                    onClick={handleShow}
                    style={{
                      width: "220px",
                      height: "225px",
                      borderRadius: "50%",
                      margin: "auto",
                    }}
                    fluid
                    variant="top"
                    id="teamCarPhoto"
                    src={data.photo}
                  />
                </div>
                <div>
                  <h5 id="coverTeamName">{data.Name}</h5>
                  <p id="coverTeamTitle">{data.Title}</p>{" "}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>{" "}
      <Modal
        show={show}
        size="xl"
        id="teamModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        {" "}
        <Modal.Body>
          <Profile data={team} onClickIndex={clickIndex} />
        </Modal.Body>{" "}
      </Modal>{" "}
    </>
  );
};

export default TeamDisplay;
