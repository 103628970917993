import React from "react";
import "./admin.css";
function Admin() {
  return (
    <div
      className="container-fluid"
      id="adminDiv"
      style={{ marginTop: "13vw" }}
    >
      <h2>Input Forms</h2>
      <ol style={{}}>
        <li id="adminLink">
          <a href="/connections/form">connections</a>
          <span></span>
        </li>
        <li>
          <a href="/client/form">client</a>
        </li>
        <li>
          <a href="/video/form">videos</a>
        </li>
        <li>
          <a href="/advisory/form">advisory</a>
        </li>
        <li>
          <a href="/practice-areas/form">practice areas</a>
        </li>
        <li>
          <a href="/team/form">team</a>
        </li>
        <li>
          <a href="/publication/form">Publications</a>
        </li>
      </ol>
    </div>
  );
}

export default Admin;
