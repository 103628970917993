import React, { useState, useEffect } from "react";
import axios from "axios";
import "./formTemp.css";
import logoNav from "../media/logo/logoNav.png";
import { Image, Table } from "react-bootstrap";
import { backendUrl } from "./api";
function PublicationForm() {
  const [publication, setPublication] = useState([]);

  const [pubFormData, setPubFormData] = useState({
    pubLink: "",
    pubTitle: "",
  });
  const resetForm = () => {
    setPubFormData({
      pubLink: "",
      pubTitle: "",

      // Set other form fields to their initial state or empty values
    });
  };
  useEffect(() => {
    getPublications();
  });

  const handleChange = (e) => {
    setPubFormData({ ...pubFormData, [e.target.name]: e.target.value });
  };
  function getPublications() {
    axios.get(`${backendUrl}/publication.php`).then(function (response) {
      setPublication(response.data);
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault(); // Make an Axios POST request to the PHP backend for the publications

    axios.post(`${backendUrl}/publication.php`, pubFormData).catch((error) => {
      console.error("Error submitting form:", error);
    });
    alert("Publication Added");
    resetForm();
  };
  const deletePublication = (title) => {
    axios
      .delete(`${backendUrl}/pubDel.php?title=${title}`)
      .then(function (response) {
        getPublications();
      });
  };
  return (
    <div className="container-fluid" id="formDiv">
      {" "}
      <form id="detailForm">
        <h4 style={{ marginTop: "0.5em" }}>New Publication</h4>
        <p style={{ color: "black" }}>This form will add a new client.</p>
        <div className="input-group">
          <label>
            Publication Title{" "}
            <input
              required
              type="text"
              name="pubTitle"
              value={pubFormData.pubTitle}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Publication Link
            <input
              required
              type="text"
              name="pubLink"
              value={pubFormData.pubLink}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            File{" "}
            <input
              type="file"
              name="pubLink"
              value={pubFormData.pubLink}
              onChange={handleChange}
            />
          </label>{" "}
        </div>

        <div className="container" id="formButtonDiv">
          <button
            className="btn btn-danger"
            id="clearBtnForm"
            onClick={resetForm}
          >
            Clear
          </button>
          <button
            className="btn btn-success"
            id="submitBtnForm"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
      <div className="container-fluid">
        <h1 style={{ padding: "15px" }}>List Publications</h1>
        <Table responsive bordered hover id="dsiplayTable">
          <thead>
            <tr>
              <th>Title</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {publication.map((data, key) => (
              <tr key={key}>
                <td style={{ textTransform: "capitalize" }}>{data.title}</td>

                <td>{data.link}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => deletePublication(data.title)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
export default PublicationForm;
