import React, { useState } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import lnGr from "../media/icons/lnGr.png";
import "./team.css";
import { GrNext, GrPrevious } from "react-icons/gr";

export const StratProfile = ({ data }) => {
  const [index, setIndex] = useState(0);
  const slideLeft = () => {
    setIndex(index === 0 ? data.length - 1 : index - 1);
  };

  const slideRight = () => {
    setIndex(index === data.length - 1 ? 0 : index + 1);
  };
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="container-fluid" id="teamCarousel">
      {/*       <h4
        style={{
          fontSize: "30px",
          letterSpacing: "3px",
          textTransform: "uppercase",
          marginTop: "3em",
          marginBottom: "1em",
        }}
      >
        Team
      </h4> */}
      <div className="container-fluid">
        {data.map((item, idx) => {
          return (
            <>
              <GrPrevious
                className="teamArrow"
                onClick={slideLeft}
                style={{ left: "1em" }}
              />

              <Card
                className={index === idx ? "index" : "index index-hidden"}
                id="teamCard"
                key={idx}
              >
                <h4 key={idx} id="memberName">
                  {item.Name}
                </h4>
                <p key={idx} id="memberTitle">
                  {item.Title}
                </p>
                <Card.Body>
                  <Row>
                    <Col
                      sm={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <Image
                        id="teamPhoto"
                        src={item.photo}
                        roundedCircle
                        fluid
                      />
                      <a
                        href="/"
                        style={{
                          width: "50%",
                          marginTop: "20px",
                        }}
                      >
                        <Image
                          src={lnGr}
                          alt="https://icons8.com/icon/447/linkedin"
                        />
                      </a>
                    </Col>
                    <Col style={{ height: "70vh", overflow: "auto" }} sm={8}>
                      <pre>
                        {" "}
                        <p id="memberBody">{item.Body}</p>
                      </pre>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          );
        })}
        <GrNext
          className="teamArrow"
          onClick={slideRight}
          style={{ right: "1em" }}
        />
      </div>
    </div>
  );
};
