import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import "./article.css";
import axios from "axios";
import logo from "../media/logo/logo.png";
import { useParams } from "react-router-dom";
import desc from "../media/images/desc.png";
import { backendUrl } from "./api";
function News() {
  const [data, setData] = useState([]);
  const { connectionTitle } = useParams();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    // We use axios to send a GET request to the php file to fetch the data
    axios
      .get(`${backendUrl}/news.php/}`)
      .then((response) => {
        // We handle the response from the php file

        // We update the result state variable with the response data
        setData(response.data);
        console.log(data);
      })
      .catch((error) => {
        // We handle the error from the php file
        console.error(error);
      });
  }, []);
  // Access the item id from the URL params

  return (
    <div className="container" id="allArticlesDiv">
      <Row>
        <h4
          style={{
            textTransform: "uppercase",
            letterSpacing: "2px",
            float: "left",
            marginBottom: "20px",
          }}
        >
          News
        </h4>
      </Row>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.map((article, idx) => (
          <>
            <a
              id="allArticleLink"
              target="blank"
              href={article.body}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {" "}
              <Card key={idx} id="allArticlesCard" as="href">
                <Card.Body>
                  <iframe
                    height="430"
                    width="900"
                    src={article.body}
                    style={{ borderRadius: "20px" }}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  />{" "}
                  <Card.Title id="allArticlesTitle">{article.title}</Card.Title>
                  <Card.Subtitle id="allArticlesSub">
                    {article.author}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </a>{" "}
          </>
        ))}
      </div>
    </div>
  );
}

export default News;
