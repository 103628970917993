import React, { useState, useEffect } from "react";
import axios from "axios";
import "./formTemp.css";
import { Image, Table } from "react-bootstrap";
import { backendUrl } from "./api";
function ConnectionForm() {
  const [connData, setConnData] = useState([]);
  const [connFormData, setConnFormData] = useState({
    connType: "",
    connTitle: "",
    connArea: "",
    connBody: "",
    connLogo: "",

    // Add other form fields as needed
  });

  const resetForm = () => {
    setConnFormData({
      connType: "",
      connTitle: "",
      connArea: "",
      connBody: "",
      connLogo: "",

      // Set other form fields to their initial state or empty values
    });
  };

  const handleChange = (e) => {
    setConnFormData({ ...connFormData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    getConnData();
  });
  function getConnData() {
    axios.get(`${backendUrl}/connection.php`).then(function (response) {
      setConnData(response.data);
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(connFormData);
    // Make an Axios POST request to the PHP backend
    axios
      .post(`${backendUrl}/connection.php`, connFormData)
      .then((response) => {
        alert("Connection Added");
        getConnData();
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });

    resetForm();
  };

  const deleteProject = (title) => {
    axios
      .delete(`${backendUrl}/connDel.php?title=${title}`)
      .then(function (response) {
        getConnData();
      });
  };
  return (
    <div className="container-fluid" id="formDiv">
      {" "}
      <form onSubmit={handleSubmit} id="detailForm">
        <h4 style={{ marginTop: "0.5em" }}>New Article</h4>
        <p style={{ color: "black" }}>This form will add a new Article.</p>{" "}
        <div className="input-group">
          <label>
            Type
            <select
              required
              onChange={handleChange}
              name="connType"
              value={connFormData.connType}
              style={{ marginLeft: "20px" }}
            >
              <option value="" disabled selected>
                Select the type
              </option>
              <option value="news">News</option>
              <option value="article">Article</option>
            </select>
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Article Title{" "}
            <input
              required
              onChange={handleChange}
              name="connTitle"
              value={connFormData.connTitle}
              type="text"
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Practice Area
            <select
              required
              onChange={handleChange}
              name="connArea"
              value={connFormData.connArea}
              style={{ marginLeft: "20px" }}
            >
              <option value="" disabled selected>
                Select the category
              </option>
              <option value="health">Health</option>
              <option value="innovation">Innovation</option>
              <option value="energy">Energy</option>
              <option value="finance">Finance</option>
            </select>
          </label>{" "}
        </div>
        <div className="input-group">
          <label>Article Body</label>{" "}
          <textarea
            required
            onChange={handleChange}
            name="connBody"
            value={connFormData.connBody}
            style={{
              marginLeft: "10px",
              width: "80%",
              height: "auto",
              overflow: "auto",
            }}
            rows="8"
          />
        </div>
        <div className="container" id="formButtonDiv">
          <button
            className="btn btn-danger"
            id="clearBtnForm"
            onClick={resetForm}
          >
            Clear
          </button>
          <button
            className="btn btn-success"
            id="submitBtnForm"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
      <div className="container-fluid">
        <h1 style={{ padding: "15px" }}>List Connections</h1>
        <Table responsive bordered hover id="dsiplayTable">
          <thead>
            <tr>
              <th>Type</th>
              <th>Title</th>
              <th>Author</th>
              <th>Area</th>
              <th>Body</th>
            </tr>
          </thead>
          <tbody>
            {connData.map((data, key) => (
              <tr key={key}>
                <td style={{ textTransform: "capitalize" }}>{data.type}</td>
                <td>{data.title}</td>
                <td>{data.author}</td>
                <td>{data.area}</td>
                <td>{data.body}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteProject(data.title)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ConnectionForm;
