import React, { useState, useEffect } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import lnGr from "../media/icons/lnGr.png";
import "./team.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import axios from "axios";
import { mediaBackend } from "./api";
export const AdvisoryProfile = ({ data, onClickIndex }) => {
  const [index, setIndex] = useState(onClickIndex);

  useEffect(() => {});

  const slideLeft = () => {
    setIndex(index === 0 ? data.length - 1 : index - 1);
  };

  const slideRight = () => {
    setIndex(index === data.length - 1 ? 0 : index + 1);
  };

  return (
    <div className="container-fluid" id="teamCarousel">
      <div className="row">
        <div className="col-sm-1">
          <GrPrevious className="teamArrow" size={40} onClick={slideLeft} />
        </div>
        <div className="col-sm-10">
          {data.map((item, idx) => {
            return (
              <div id="teamMemberModal" className="container-fluid">
                <Card
                  className={index === idx ? "index" : "index index-hidden"}
                  id="teamCard"
                  key={idx}
                >
                  <Image
                    fluid
                    //Change here in case file path is different
                    src={`${mediaBackend}${item.photo}`}
                    key={item.name}
                    style={{
                      maxHeight: "250px",
                      maxWidth: "200px",
                      margin: "auto",

                      borderRadius: "50%",
                    }}
                    id="pAreaContact"
                    roundedCircle
                  />
                  <Card.Body>
                    <div className="container-fluid" id="teamInfo">
                      <h4 id="memberName">{item.name}</h4>
                      <p id="memberTitle">{item.title}</p>
                      <div id="teamCarBody">
                        <pre>
                          {" "}
                          <p id="memberBody">{item.body}</p>
                        </pre>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
        <div className="col-sm-1">
          {" "}
          <GrNext className="teamArrow" size={40} onClick={slideRight} />
        </div>
      </div>
    </div>
  );
};
