import React, { useState, useEffect } from "react";
import axios from "axios";
import "./formTemp.css";
import logoNav from "../media/logo/logoNav.png";
import { Image, Table } from "react-bootstrap";
import { backendUrl } from "./api";
function PaForm() {
  const [area, setArea] = useState([]);
  const [teamContact, setTeamContact] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    contact: "",
    area: "",
    summary: "",
    clientLink: "",
    clientImage: "",
    // Add other form fields as needed
  });
  const resetForm = () => {
    setFormData({
      title: "",
      contact: "",
      area: "",
      summary: "",
      clientLink: "",
      clientImage: "",

      // Set other form fields to their initial state or empty values
    });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    getAreas();
  }, []);
  function getAreas() {
    axios.get(`${backendUrl}/practice/`).then(function (response) {
      setArea(response.data);
    });
    axios.get(`${backendUrl}/team/getTeam.php`).then(function (res) {
      setTeamContact(res.data);
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    // Make an Axios POST request to the PHP backend
    axios
      .post(`${backendUrl}/practice/`, formData)
      .then((response) => {
        getAreas(); // Handle the response from the PHP backend
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
    alert("Submission Successful");
    resetForm();
    getAreas();
  };
  const deleteProject = (title) => {
    axios
      .delete(`${backendUrl}/practice/del.php?title=${title}`)
      .then(function (response) {
        getAreas();
      });
  };

  return (
    <div className="container-fluid" id="formDiv">
      {" "}
      <form onSubmit={handleSubmit} id="detailForm">
        <h4 style={{ marginTop: "0.5em" }}>New Practice Area Project</h4>
        <p style={{ color: "black" }}>This form will add a new project.</p>
        <div className="input-group">
          <label>
            Project Title{" "}
            <input
              required
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Team Member Name:
            <select
              name="contact"
              required
              value={formData.contact}
              onChange={handleChange}
              style={{ marginLeft: "20px" }}
            >
              <option value="" disabled selected>
                Contact
              </option>
              {teamContact.map((data, key) => (
                <option value={data.name}>{data.name}</option>
              ))}
            </select>
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            Practice Area
            <select
              onChange={handleChange}
              name="area"
              required
              value={formData.area}
              style={{ marginLeft: "20px" }}
            >
              <option value="" disabled selected>
                Select the category
              </option>
              <option value="health">Health</option>
              <option value="innovation">Innovation</option>
              <option value="energy">Energy</option>
              <option value="finance">Finance</option>
            </select>
          </label>{" "}
        </div>
        <div className="input-group">
          <label>Project Summary</label>{" "}
          <textarea
            name="summary"
            required
            value={formData.summary}
            onChange={handleChange}
            style={{
              marginLeft: "10px",
              width: "80%",
              height: "auto",
              overflow: "auto",
            }}
            rows="8"
          />
        </div>
        <div className="input-group">
          <label>
            Client Link
            <input
              required
              type="text"
              name="title"
              value={formData.clientLink}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="input-group">
          <label>
            client Photo
            <input
              required
              type="text"
              name="title"
              value={formData.clientTitle}
              onChange={handleChange}
            />
          </label>{" "}
        </div>
        <div className="container" id="formButtonDiv">
          <button
            className="btn btn-danger"
            id="clearBtnForm"
            onClick={resetForm}
          >
            Clear
          </button>
          <button
            className="btn btn-success"
            id="submitBtnForm"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
      <div className="container-fluid">
        <h1 style={{ padding: "15px" }}>List Projects</h1>
        <Table responsive bordered hover id="dsiplayTable">
          <thead>
            <tr>
              <th>Title</th>
              <th>Contact</th>
              <th>Summary</th>
              <th>Area</th>
              <th>Client Website Link</th>
              <th>Client Image</th>
            </tr>
          </thead>
          <tbody>
            {area.reverse().map((data, key) => (
              <tr key={key}>
                <td style={{ textTransform: "capitalize" }}>{data.title}</td>
                <td>{data.contact}</td>
                <td>{data.body}</td>
                <td>{data.category}</td>
                <td>{data.clientLink}</td>
                <td>{data.clientImage}</td>

                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteProject(data.title)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default PaForm;
