import React, { useState, useEffect } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import lnGr from "../media/icons/lnGr.png";
import "./team.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import axios from "axios";
import { backendUrl, mediaBackend } from "./api";

export const Profile = ({ data, onClickIndex }) => {
  const [index, setIndex] = useState(onClickIndex);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check on component mount
    handleResize();

    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const slideLeft = () => {
    setIndex(index === 0 ? data.length - 1 : index - 1);
  };

  const slideRight = () => {
    setIndex(index === data.length - 1 ? 0 : index + 1);
  };

  return (
    <div className="container-fluid" id="teamCarousel">
      <div className="row">
        {isSmallScreen ? (
          <div className="container-fluid" style={{ padding: "0" }}>
            {data.map((item, idx) => (
              <div
                className={index === idx ? "index" : "index index-hidden"}
                id="teamSmall"
              >
                <div id="teamModalSection">
                  {" "}
                  <Image
                    fluid
                    src={`${mediaBackend}${item.photo}`}
                    alt={item.name}
                    style={{
                      maxHeight: "250px",
                      maxWidth: "200px",
                      margin: "auto",
                      borderRadius: "50%",
                    }}
                    id="pAreaContact"
                    roundedCircle
                  />
                </div>
                <section>
                  <h4 id="memberName">{item.name}</h4>
                  <p id="memberTitle">{item.title}</p>
                  <div className="container" id="contactImageCard">
                    <a
                      href={item.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        margin: "auto",
                      }}
                    >
                      <Image fluid src={lnGr} alt="LinkedIn" />
                    </a>
                  </div>
                  <div
                    className="container-fluid"
                    style={{
                      padding: "0",
                      height: "250vw",
                      overflow: "auto",
                    }}
                  >
                    <pre>
                      <p id="memberBody">{item.body}</p>
                    </pre>
                  </div>
                </section>
              </div>
            ))}
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-1">
                <GrPrevious
                  className="teamArrow"
                  size={40}
                  onClick={slideLeft}
                />
              </div>
              <div className="col-sm-10">
                {data.map((item, idx) => (
                  <div
                    id="teamMemberModal"
                    className="container-fluid"
                    key={idx}
                  >
                    <Card
                      className={index === idx ? "index" : "index index-hidden"}
                      id="teamCard"
                    >
                      <Image
                        fluid
                        src={`${mediaBackend}${item.photo}`}
                        alt={item.name}
                        style={{
                          maxHeight: "250px",
                          maxWidth: "200px",
                          margin: "auto",
                          borderRadius: "50%",
                        }}
                        id="pAreaContact"
                        roundedCircle
                      />
                      <Card.Body>
                        <div className="container-fluid" id="teamInfo">
                          <h4 id="memberName">{item.name}</h4>
                          <p id="memberTitle">{item.title}</p>
                          <div className="container" id="contactImageCard">
                            <a
                              href={item.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                margin: "auto",
                              }}
                            >
                              <Image fluid src={lnGr} alt="LinkedIn" />
                            </a>
                          </div>
                          <div className="container-fluid" id="teamCarBody">
                            <pre>
                              <p id="memberBody">{item.body}</p>
                            </pre>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
              <div className="col-sm-1">
                <GrNext className="teamArrow" size={40} onClick={slideRight} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
