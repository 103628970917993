import React, { useState, useEffect } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import lnGr from "../media/icons/lnGr.png";
import "./connect.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import axios from "axios";

export const NewsViewer = ({ data }) => {
  const [index, setIndex] = useState(0);
  return (
    <div className="container-fluid" id="newsViewerDiv">
      {" "}
      {data.map((news, idx) => {
        return (
          <div
            key={idx}
            className={index === idx ? "index" : "index index-hidden"}
            onClick={() => {
              setIndex(0);
            }}
            id="currentNewsDiv"
          >
            <h2 id="newsTitle">{news.title}</h2>
            <h6
              id="newsDate"
              style={{ marginBottom: "2em", textTransform: "capitalize" }}
            >
              {news.area}
            </h6>
            <div
              className="container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <iframe
                height="430"
                width="900"
                src={news.body}
                style={{
                  borderRadius: "20px",
                }}
                frameborder="0"
                allowfullscreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />{" "}
            </div>
          </div>
        );
      })}
    </div>
  );
};
