export const category = [
  { name: "DFI’s & Multilaterals" },
  { name: "Governments" },
  { name: "Developing & Implementing partners" },
  { name: "Foundations, Academia and Research Institutes" },
  { name: "Private sector client" },
  { name: "Venture capitalists and impact investors" },
];
export const clientAll = [
  {
    alt: "Gavi, The Vaccine Alliance",
    photo: require("../media/clients/gavi.png"),
    category: "DFI’s & Multilaterals",
  },

  {
    alt: "World Bank Group",
    photo: require("../media/clients/twb.png"),
    category: "DFI’s & Multilaterals",
  },

  {
    alt: "Global Fund",
    photo: require("../media/clients/gf.png"),
    category: "DFI’s & Multilaterals",
  },

  {
    alt: "USAID",

    photo: require("../media/clients/usaid.png"),
    category: "DFI’s & Multilaterals",
  },

  {
    alt: "UNDP",

    photo: require("../media/clients/undp.png"),
    category: "DFI’s & Multilaterals",
  },

  {
    alt: "International Finance Corporation (IFC)",

    photo: require("../media/clients/ifc.png"),
    category: "DFI’s & Multilaterals",
  },

  {
    alt: "United Nations Commission for Africa (UNECA)",

    photo: require("../media/clients/uneca.png"),
    category: "DFI’s & Multilaterals",
  },

  {
    alt: "Swedfund",

    photo: require("../media/clients/sf.png"),
    category: "DFI’s & Multilaterals",
  },
  {
    alt: "Finnfund",

    photo: require("../media/clients/ff.png"),
    category: "DFI’s & Multilaterals",
  },
  {
    alt: "Proparco",

    photo: require("../media/clients/pp.png"),
    category: "DFI’s & Multilaterals",
  },
  {
    alt: "Swiss Re",

    photo: require("../media/clients/sre.png"),
    category: "DFI’s & Multilaterals",
  },
  {
    alt: "kenya",

    photo: require("../media/clients/ke1.png"),
    category: "govt",
  },

  {
    alt: "Uganda",

    photo: require("../media/clients/ug.png"),
    category: "govt",
  },

  {
    alt: "Rwanda",

    photo: require("../media/clients/rw.png"),
    category: "govt",
  },

  {
    alt: "Tanzania",

    photo: require("../media/clients/tz.png"),
    category: "govt",
  },
  {
    alt: "Mozambique",

    photo: require("../media/clients/mz.png"),
    category: "govt",
  },
  {
    alt: "Nigeria",

    photo: require("../media/clients/ng.png"),
    category: "govt",
  },
  {
    alt: "Ghana",

    photo: require("../media/clients/gh.png"),
    category: "govt",
  },
  {
    alt: "Ethiopia",

    photo: require("../media/clients/et.png"),
    category: "govt",
  },
  {
    alt: "Zambia",

    photo: require("../media/clients/zm.png"),
    category: "govt",
  },
  {
    alt: "GREA",

    photo: require("../media/clients/grea.png"),
    category: "dip",
  },

  {
    alt: "Living Goods",

    photo: require("../media/clients/lg.png"),
    category: "dip",
  },

  {
    alt: "Gavi, The Vaccine Alliance",

    photo: require("../media/clients/gavi.png"),
    category: "dip",
  },

  {
    alt: "Amref Health",

    photo: require("../media/clients/am.png"),
    category: "dip",
  },

  {
    alt: "Management Science for health",
    photo: require("../media/clients/msh.png"),
    category: "dip",
  },
  {
    alt: "Wellcome",

    photo: require("../media/clients/wc.png"),
    category: "research",
  },
  {
    alt: "Lemelson Foundation",

    photo: require("../media/clients/tlf.png"),
    category: "research",
  },
  {
    alt: "Population Council Kenya",

    photo: require("../media/clients/pck.png"),
    category: "research",
  },
  {
    alt: "Bill and Melinda Gates Foundation",

    photo: require("../media/clients/bmg.png"),
    category: "research",
  },
  {
    alt: "John Snow Inc",

    photo: require("../media/clients/jsi.png"),
    category: "research",
  },
  {
    alt: "Mastercard Foundation",

    photo: require("../media/clients/mc.png"),
    category: "research",
  },
  {
    alt: "Merck",

    photo: require("../media/clients/mk.png"),
    category: "private",
  },
  {
    alt: "Medaccess",

    photo: require("../media/clients/ma.png"),
    category: "private",
  },
  {
    alt: "mPharma",

    photo: require("../media/clients/mp.png"),
    category: "private",
  },
  {
    alt: "GSK",

    photo: require("../media/clients/gsk.png"),
    category: "private",
  },
  {
    alt: "Medtronic",

    photo: require("../media/clients/md.png"),
    category: "private",
  },
  {
    alt: "Roche",

    photo: require("../media/clients/rc.png"),
    category: "private",
  },
  {
    alt: "Lily",

    photo: require("../media/clients/ll.png"),
    category: "private",
  },
  {
    alt: "Biomerieux",

    photo: require("../media/clients/bm.png"),
    category: "private",
  },
  {
    alt: "Bayer",

    photo: require("../media/clients/bayer.png"),
    category: "private",
  },
  {
    alt: "Pyramid pharma",

    photo: require("../media/clients/pharma.png"),
    category: "private",
  },
  {
    alt: "Carbon Trust",

    photo: require("../media/clients/ct.png"),
    category: "private",
  },
  {
    alt: "Odyssey",

    photo: require("../media/clients/oy.png"),
    category: "private",
  },
  {
    alt: "GivePower",

    photo: require("../media/clients/gp.png"),
    category: "private",
  },
  {
    alt: "Energy for Impact",

    photo: require("../media/clients/ei.png"),
    category: "private",
  },
  {
    alt: "Goodlife Pharmacies",

    photo: require("../media/clients/gl.png"),
    category: "private",
  },
  {
    alt: "Equity Afia Medical Center",

    photo: require("../media/clients/ea.png"),
    category: "private",
  },
  {
    alt: "Moi Teaching Referral Hospital",

    photo: require("../media/clients/mth.png"),
    category: "private",
  },
  {
    alt: "CFW Clinics",

    photo: require("../media/clients/cfw.png"),
    category: "private",
  },
  {
    alt: "The Karen Hospital",

    photo: require("../media/clients/kh.png"),
    category: "private",
  },
  {
    alt: "Diani Beach Hospital",

    photo: require("../media/clients/dbh.png"),
    category: "private",
  },
  {
    alt: "Haltons Pharmacy",

    photo: require("../media/clients/hp.png"),
    category: "private",
  },

  {
    alt: "Leapfrog",

    photo: require("../media/clients/lf.png"),
    category: "venture",
  },
  {
    alt: "Seros Economic Development Fund",

    photo: require("../media/clients/sedf.png"),
    category: "venture",
  },
  {
    alt: "Ascent",

    photo: require("../media/clients/ascent.png"),
    category: "venture",
  },
  {
    alt: "The Abraaj group",

    photo: require("../media/clients/ag.png"),
    category: "venture",
  },
];
