import React, { useEffect, useState } from "react";
import axios from "axios";
import "./clients.css";
import im from "../media/images/im.jpg";
import { clientAll } from "./clientAll";
import { Card, Accordion } from "react-bootstrap";
import { category } from "./clientAll";
import { backendUrl, mediaBackend } from "./api";
export default function Clients() {
  const [clients, setClients] = useState([]);
  const [isHovering, setIsHovering] = useState(false);

  /*  const handleMouseOver = () => {
    setIsHovering(true);
  }; */

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const [clientType, setClientType] = useState("");

  const [filteredData, setFilteredData] = useState([clientType]);
  const filterData = (clientType) => {
    const filteredData = clients.filter((item) => item.category === clientType);

    setFilteredData(filteredData);
  };
  function handleMouseOver() {
    setIsHovering(true);
  }
  const clientCategory = (event) => {
    setClientType(event.target.id);
    filterData(event.target.name);
    setIsHovering(true);
  };

  useEffect(() => {
    axios
      .get(`${backendUrl}/clients.php`)
      .then((response) => {
        setClients(response.data);

        // Handle the fetched data
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
    const filteredData = clients.filter((item) => item.category === clientType);

    setFilteredData(filteredData);
  }, []);

  return (
    <div
      className="container-fluid"
      id="clientsDiv"
      style={{
        backgroundImage: "url(" + im + ")",
      }}
    >
      <div className="container-fluid" id="clientsGrid">
        {" "}
        <h4
          style={{
            marginTop: "15px",
            textTransform: "Capitalize",
          }}
        >
          {clientType} Clients
        </h4>
        <div className="clientAccordionDiv">
          {" "}
          <Accordion>
            <Accordion.Item id="clientAccordion" eventKey="0">
              <Accordion.Header>
                {" "}
                <button
                  variant="outline-light"
                  size="lg"
                  onClick={clientCategory}
                  // style={{ border: bckgColor }}
                  className="clientButton"
                  name="DFI’s & Multilaterals"
                  id="DFI’s & Multilaterals"
                >
                  DFI’s & Multilaterals
                </button>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  className="container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    textTransform: "Capitalize",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {filteredData.map((client, idx) => (
                    <Card id="clientCard" onMouseOver={handleMouseOver}>
                      <Card.Img
                        variant="top"
                        id="clientInfoImage"
                        src={`${mediaBackend}${client.photo}`}
                      />
                      <Card.Body
                        key={idx}
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {isHovering && <h5 id="clientName">{client.alt}</h5>}
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item id="clientAccordion" eventKey="1">
              <Accordion.Header>
                {" "}
                <button
                  variant="outline-light"
                  size="lg"
                  onClick={(event) => {
                    clientCategory(event);
                    handleMouseOver();
                  }}
                  // style={{ border: bckgColor }}
                  className="clientButton"
                  name="govt"
                  id="Government"
                >
                  Governments
                </button>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  className="container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    textTransform: "Capitalize",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {filteredData.map((client, idx) => (
                    <Card id="clientCard" onMouseOver={handleMouseOver}>
                      <Card.Img
                        variant="top"
                        id="clientInfoImage"
                        src={`${mediaBackend}${client.photo}`}
                        style={{ maxWidth: "180px" }}
                      />
                      <Card.Body
                        key={idx}
                        id="clientInfoCard"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {isHovering && <h5 id="clientName">{client.alt}</h5>}
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item id="clientAccordion" eventKey="2">
              <Accordion.Header>
                {" "}
                <button
                  variant="outline-light"
                  size="lg"
                  onClick={clientCategory}
                  // style={{ border: bckgColor }}
                  className="clientButton"
                  name="dip"
                  id="Developing & Implementing partner"
                >
                  Developing & Implementing partners
                </button>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  className="container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    textTransform: "Capitalize",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {filteredData.map((client, idx) => (
                    <Card id="clientCard" onMouseOver={handleMouseOver}>
                      <Card.Img
                        variant="top"
                        id="clientInfoImage"
                        src={`${mediaBackend}${client.photo}`}
                      />
                      <Card.Body
                        key={idx}
                        id="clientInfoCard"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {isHovering && <h5 id="clientName">{client.alt}</h5>}
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item id="clientAccordion" eventKey="3">
              <Accordion.Header>
                {" "}
                <button
                  variant="outline-light"
                  size="lg"
                  onClick={clientCategory}
                  // style={{ border: bckgColor }}
                  className="clientButton"
                  name="research"
                  id="Foundations, Academia and Research Institute"
                >
                  Foundations, Academia and Research Institutes
                </button>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  className="container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    textTransform: "Capitalize",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {filteredData.map((client, idx) => (
                    <Card id="clientCard" onMouseOver={handleMouseOver}>
                      <Card.Img
                        variant="top"
                        id="clientInfoImage"
                        src={`${mediaBackend}${client.photo}`}
                      />
                      <Card.Body
                        key={idx}
                        id="clientInfoCard"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {isHovering && <h5 id="clientName">{client.alt}</h5>}
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item id="clientAccordion" eventKey="4">
              <Accordion.Header>
                {" "}
                <button
                  variant="outline-light"
                  size="lg"
                  onClick={clientCategory}
                  // style={{ border: bckgColor }}
                  className="clientButton"
                  name="private"
                  id="Private Sector"
                >
                  Private sector clients
                </button>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  className="container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    textTransform: "Capitalize",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {filteredData.map((client, idx) => (
                    <Card id="clientCard" onMouseOver={handleMouseOver}>
                      <Card.Img
                        variant="top"
                        id="clientInfoImage"
                        src={`${mediaBackend}${client.photo}`}
                      />
                      <Card.Body
                        key={idx}
                        id="clientInfoCard"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {isHovering && <h5 id="clientName">{client.alt}</h5>}
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item id="clientAccordion" eventKey="5">
              <Accordion.Header>
                {" "}
                <button
                  variant="outline-light"
                  size="lg"
                  onClick={clientCategory}
                  // style={{ border: bckgColor }}
                  className="clientButton"
                  name="venture"
                  id="Venture capitalists and impact investor"
                >
                  Venture capitalists and impact investors
                </button>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  className="container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    textTransform: "Capitalize",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {filteredData.map((client, idx) => (
                    <Card id="clientCard" onMouseOver={handleMouseOver}>
                      <Card.Img
                        variant="top"
                        id="clientInfoImage"
                        src={`${mediaBackend}${client.photo}`}
                      />
                      <Card.Body
                        key={idx}
                        id="clientInfoCard"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {isHovering && <h5 id="clientName">{client.alt}</h5>}
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>{" "}
        </div>
      </div>
    </div>
  );
}
