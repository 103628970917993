import React, { useEffect, useState } from "react";
import axios from "axios";
import { TeamMembers } from "./teamMembers";
import "./practiceAll.css";
import {
  Card,
  Col,
  Row,
  Modal,
  Image,
  ButtonGroup,
  Carousel,
  ToggleButton,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import lnGr from "../media/icons/lnGr.png";
import { backendUrl, mediaBackend } from "./api";

export default function PracticeAreas() {
  const [team, setTeam] = useState([]);
  const areas = [
    { name: "health" },
    { name: "energy" },
    { name: "finance" },
    { name: "innovation" },
  ];
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [practiceTitle, setPracticeTitle] = useState("health");
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [data, setData] = useState([]);
  const [client, setClient] = useState([]);
  /*   const getClient = (clientName) => {
    axios
      .get(`${backendUrl}/clientWebSearch.php?client=${clientName}`)
      .then(function (response) {
        setClient(response.data);
        console.log(client);
      });
  }; */

  const searchTeam = (name) => {
    axios
      .get(`${backendUrl}/team/teamSearch.php?name=${name}`)
      .then(function (response) {
        setTeam(response.data);
        setShow(true);
      });
  };
  useEffect(() => {
    fetchData(practiceTitle);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check on component mount
    handleResize();

    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [practiceTitle]);

  // Use state to manage the toggle state, set an initial value (true for "on" or false for "off")

  // Function to fetch data based on query parameter
  const fetchData = async (practiceTitle) => {
    try {
      const response = await axios.get(
        `${backendUrl}/practiceSearch.php?category=${practiceTitle}`
      );
      setData(response.data);
      const res = await axios.get(
        `${backendUrl}/clientWebSearch.php?client=${response.data.clients}`
      );

      setClient(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to handle button click and set query parameter
  const handleClick = (practiceTitle) => {
    setPracticeTitle(practiceTitle);
  };

  let uniqueContact = [...new Set(data.map((project) => project.contact))];

  return (
    <div className="container-fluid" id="practiceAllDiv">
      <div className="container-fluid" id="practiceContentDiv">
        {" "}
        <h4 style={{ marginTop: "10px", textTransform: "uppercase" }}>
          {practiceTitle} PRACTICE AREAS
        </h4>
        {isSmallScreen ? (
          <div className="container-fluid">
            <Accordion>
              <Accordion.Item id="projectAccordion" eventKey="0">
                <Accordion.Header>
                  {" "}
                  <button
                    variant="outline-light"
                    size="lg"
                    onClick={() => handleClick("health")}
                    // style={{ border: bckgColor }}
                    className="clientButton"
                    name="health"
                    id="health"
                  >
                    HEALTH
                  </button>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="container-fluid"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      textTransform: "capitalize",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {data.map((project, idx) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              searchTeam(project.contact);
                            }}
                            id="fieldMemberLink"
                          >
                            <Image
                              fluid
                              src={project.photo}
                              style={{
                                borderRadius: "50%",
                              }}
                              id="pAreaContact"
                              roundedCircle
                            />{" "}
                            <h4
                              style={{
                                padding: "10px",
                                fontSize: "20px",
                              }}
                            >
                              {project.contact}
                            </h4>{" "}
                          </div>{" "}
                          <div className="container-fluid">
                            {" "}
                            <Card id="projectCards">
                              <Card.Body id="practiceCardBody">
                                {" "}
                                <h3 id="projectTitle">{project.title}</h3>{" "}
                                <pre>
                                  <p
                                    id="projectBody"
                                    style={{
                                      textAlign: "left",
                                      letterSpacing: "2px",
                                      wordSpacing: "2px",
                                    }}
                                  >
                                    {project.body}
                                  </p>{" "}
                                </pre>
                              </Card.Body>{" "}
                            </Card>
                          </div>{" "}
                        </>
                      );
                    })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item id="projectAccordion" eventKey="1">
                <Accordion.Header>
                  {" "}
                  <button
                    variant="outline-light"
                    size="lg"
                    onClick={() => handleClick("energy")}
                    // style={{ border: bckgColor }}
                    className="clientButton"
                    name="energy"
                    id="energy"
                  >
                    ENERGY
                  </button>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="container"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      textTransform: "Capitalize",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {data.map((project, idx) => {
                      return (
                        <>
                          {" "}
                          <div
                            onClick={() => {
                              searchTeam(project.contact);
                            }}
                            id="fieldMemberLink"
                          >
                            <Image
                              fluid
                              src={project.photo}
                              style={{
                                borderRadius: "50%",
                              }}
                              id="pAreaContact"
                              roundedCircle
                            />{" "}
                            <h4
                              style={{
                                padding: "10px",
                                fontSize: "20px",
                              }}
                            >
                              {project.contact}
                            </h4>{" "}
                          </div>{" "}
                          <div className="container">
                            {" "}
                            <Card id="projectCards">
                              <Card.Body id="practiceCardBody">
                                {" "}
                                <h3 id="projectTitle">{project.title}</h3>{" "}
                                <pre>
                                  <p
                                    id="projectBody"
                                    style={{
                                      textAlign: "left",
                                      letterSpacing: "2px",
                                      wordSpacing: "2px",
                                    }}
                                  >
                                    {project.body}
                                  </p>{" "}
                                </pre>
                              </Card.Body>{" "}
                            </Card>
                          </div>{" "}
                        </>
                      );
                    })}{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item id="projectAccordion" eventKey="2">
                <Accordion.Header>
                  {" "}
                  <button
                    variant="outline-light"
                    size="lg"
                    onClick={() => handleClick("finance")}
                    // style={{ border: bckgColor }}
                    className="clientButton"
                    name="finance"
                    id="finance"
                  >
                    FINANCE
                  </button>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="container"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      textTransform: "Capitalize",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {data.map((project, idx) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              searchTeam(project.contact);
                            }}
                            id="fieldMemberLink"
                          >
                            <Image
                              fluid
                              src={project.photo}
                              style={{
                                borderRadius: "50%",
                              }}
                              id="pAreaContact"
                              roundedCircle
                            />{" "}
                            <h4
                              style={{
                                padding: "10px",
                                fontSize: "20px",
                              }}
                            >
                              {project.contact}
                            </h4>{" "}
                          </div>
                          <div className="container">
                            {" "}
                            <Card id="projectCards">
                              <Card.Body id="practiceCardBody">
                                {" "}
                                <h3 id="projectTitle">{project.title}</h3>{" "}
                                <pre>
                                  <p
                                    id="projectBody"
                                    style={{
                                      textAlign: "left",
                                      letterSpacing: "2px",
                                      wordSpacing: "2px",
                                    }}
                                  >
                                    {project.body}
                                  </p>{" "}
                                </pre>
                              </Card.Body>{" "}
                            </Card>
                          </div>{" "}
                        </>
                      );
                    })}{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item id="projectAccordion" eventKey="3">
                <Accordion.Header>
                  {" "}
                  <button
                    variant="outline-light"
                    size="lg"
                    onClick={() => handleClick("innovation")}
                    // style={{ border: bckgColor }}
                    className="clientButton"
                    name="innovation"
                    id="innovation"
                  >
                    INNOVATION
                  </button>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="container"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      textTransform: "Capitalize",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {data.map((project, idx) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              searchTeam(project.contact);
                            }}
                            id="fieldMemberLink"
                          >
                            <Image
                              fluid
                              src={project.photo}
                              style={{
                                borderRadius: "50%",
                              }}
                              id="pAreaContact"
                              roundedCircle
                            />{" "}
                            <h4
                              style={{
                                padding: "10px",
                                fontSize: "20px",
                              }}
                            >
                              {project.contact}
                            </h4>{" "}
                          </div>
                          <div className="container">
                            {" "}
                            <Card id="projectCards">
                              <Card.Body id="practiceCardBody">
                                {" "}
                                <h3 id="projectTitle">{project.title}</h3>{" "}
                                <pre>
                                  <p
                                    id="projectBody"
                                    style={{
                                      textAlign: "left",
                                      letterSpacing: "2px",
                                      wordSpacing: "2px",
                                    }}
                                  >
                                    {project.body}
                                  </p>{" "}
                                </pre>
                              </Card.Body>{" "}
                            </Card>
                          </div>{" "}
                        </>
                      );
                    })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ) : (
          <div className="row" id="practiceWhatRow">
            <div className="container-fluid" id="practiceAreaBtnGrp">
              {areas.map((area, idx) => (
                <div className="btn-group" id="buttonDiv">
                  <button
                    name={area.name}
                    type="button"
                    className={`btn btn-primary ${
                      practiceTitle === area.name ? "active" : ""
                    }`}
                    data-toggle="button"
                    aria-pressed={
                      practiceTitle === area.name ? "true" : "false"
                    }
                    key={idx}
                    onClick={() => handleClick(area.name)}
                    value={area.value}
                    id="practiceBtn"
                  >
                    {" "}
                    {area.name}
                  </button>
                </div>
              ))}
            </div>{" "}
            <div className="container" id="practiceDetailsDiv">
              <div className="row">
                <Col
                  className="column hidden-on-small"
                  id="contactColPractice"
                  md={2}
                  style={{ marginTop: "10px" }}
                >
                  <h4 style={{ justifyContent: "center", color: "#1AA99F" }}>
                    Contact
                  </h4>
                </Col>
                <Col md={7} style={{ marginTop: "10px" }}>
                  <h4 style={{ justifyContent: "center", color: "#1AA99F" }}>
                    Previous Project and Engagements{" "}
                  </h4>
                </Col>
                <Col md={2} style={{ marginTop: "10px" }}>
                  <h4 style={{ justifyContent: "center", color: "#1AA99F" }}>
                    Client
                  </h4>
                </Col>
              </div>
              {data.map((project, index) => (
                <div key={index} style={{ display: "flex", marginTop: "30px" }}>
                  {/* First column with name and photo */}
                  <div style={{ marginRight: "20px" }}>
                    <Card
                      onClick={() => {
                        searchTeam(project.contact);
                      }}
                      id="contactCardPractice"
                    >
                      {" "}
                      <Card.Body>
                        <Image
                          fluid
                          src={`${mediaBackend}${project.photo}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                          }}
                          id="pAreaContact"
                          roundedCircle
                        />
                        <h4
                          style={{
                            padding: "10px",
                            fontSize: "20px",
                          }}
                        >
                          {project.contact}
                        </h4>{" "}
                      </Card.Body>{" "}
                    </Card>
                  </div>
                  {/* Second column with paragraph of data */}
                  <div className="container">
                    {" "}
                    <Row>
                      <Col md={8}>
                        {" "}
                        <h3 id="projectTitle">{project.title}</h3>{" "}
                        <pre>
                          <p
                            id="projectBody"
                            style={{
                              textAlign: "left",
                              letterSpacing: "2px",
                              wordSpacing: "2px",
                            }}
                          >
                            {project.body}
                          </p>{" "}
                        </pre>
                      </Col>
                      <Col>
                        {" "}
                        <a target="blank" href={project.clientLink}>
                          {" "}
                          <Image
                            fluid
                            src={`${mediaBackend}${project.clientImage}`}
                            style={{
                              height: "160px",
                            }}
                            id="pAreaClient"
                          />
                        </a>
                      </Col>
                    </Row>{" "}
                  </div>{" "}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>{" "}
      <Modal
        show={show}
        responsive
        size="xl"
        id="teamModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        {" "}
        <Modal.Body>
          <div
            className="container-fluid"
            id="modalDiv"
            style={{ height: "auto" }}
          >
            {" "}
            {team.map((item) => {
              return (
                <div className="container-fluid" id="teamModal">
                  <div className="container" id="teamHeader">
                    <Image
                      fluid
                      src={`${mediaBackend}${item.photo}`}
                      style={{
                        maxHeight: "250px",
                        maxWidth: "200px",
                        margin: "auto",
                        borderRadius: "50%",
                      }}
                      id="pAreaContact"
                      roundedCircle
                    />
                    <h4 key={item.name} id="memberName">
                      {item.name}
                    </h4>
                    <p key={item.name} id="memberTitle">
                      {item.title}
                      <br />
                    </p>
                    <div className="container" id="contactImageCard">
                      <a
                        href={item.linkedin}
                        target="blank"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          margin: "auto",
                        }}
                      >
                        <Image
                          fluid
                          src={lnGr}
                          alt="https://icons8.com/icon/447/linkedin"
                        />
                      </a>
                    </div>
                  </div>

                  <div id="teamCarBody" sm={8}>
                    <pre>
                      {" "}
                      <p id="memberBody">{item.body}</p>
                    </pre>
                  </div>
                </div>
              );
            })}{" "}
          </div>
        </Modal.Body>{" "}
      </Modal>
    </div>
  );
}
