import React, { useState } from "react";
import { Nav, Image, Navbar, NavDropdown, Container } from "react-bootstrap";
import logoNav from "../media/logo/logoNav.png";
import "./nav.css";

function NavBar() {
  const [navTextColor, setNavTextColor] = useState("white");

  return (
    <Navbar
      expand="lg"
      style={{ backgroundCcolor: "#041f31" }}
      sticky="top"
      id="navId"
    >
      <Navbar.Brand href="/" style={{ marginLeft: "3%" }}>
        {" "}
        <Image src={logoNav} />
      </Navbar.Brand>
      <Navbar.Toggle
        style={{ border: "2px solid #1AA99F", marginRight: "5%" }}
        aria-controls="responsive-navbar-nav"
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav style={{ color: navTextColor, textAlign: "left" }}>
          <Nav.Link
            style={{ color: navTextColor }}
            id="navLink"
            href="/who-we-are/"
          >
            WHO WE ARE
          </Nav.Link>
          <Nav.Link
            style={{ color: navTextColor }}
            id="navLink"
            href="/what-we-do/"
          >
            WHAT WE DO
          </Nav.Link>
          <Nav.Link
            style={{ color: navTextColor }}
            id="navLink"
            href="/clients"
          >
            CLIENTS
          </Nav.Link>
          <Nav.Link
            style={{ color: navTextColor }}
            id="navLink"
            href="/e&k-connect"
          >
            E&K CONNECT
          </Nav.Link>

          <Nav.Link
            style={{ color: navTextColor }}
            id="navLink"
            href="/contact-us"
          >
            CONTACT US
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default NavBar;
