import React, { useState, useEffect } from "react";
import "./formTemp.css";
import logoNav from "../media/logo/logoNav.png";
import { Form, Card, Row, Col, Image } from "react-bootstrap";

function FormTemp() {
  const [seriesStatus, setseriesStatus] = useState("yes");
  const [newSeries, setNewSeries] = useState(false);
  const [oneOff, setOneOff] = useState(false);
  const [existingSeries, setExistingSeries] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    file: "",

    // Add other form fields as needed
  });
  const resetForm = () => {
    setFormData({
      name: "",
      category: "",
      file: "",
      // Set other form fields to their initial state or empty values
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (seriesStatus == "yes") {
      setExistingSeries(true);
      setOneOff(false);
      setNewSeries(false);
    }
    if (seriesStatus == "no") {
      setNewSeries(true);
      setOneOff(false);
      setExistingSeries(false);
    }
    if (seriesStatus == "one") {
      setOneOff(true);
      setNewSeries(false);
    }
    event.preventDefault();
  };
  const handleChange = (e) => {
    setseriesStatus(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="container-fluid" style={{ marginTop: "6em" }}>
      <div className="container" id="formDiv">
        <form id="detailForm">
          <span>
            <Image src={logoNav} style={{ height: "auto" }} fluid />
          </span>{" "}
          <h4 style={{ marginTop: "1em" }}>New Podcast/Video</h4>
          <p style={{ color: "black" }}>This form will add a new client.</p>
          <div className="input-group">
            <label>
              Video / Podcast Title <input type="text" />
            </label>{" "}
          </div>
          <div className="input-group">
            <label>
              Video / Podcast Link
              <input type="text" />
            </label>{" "}
          </div>
          <div className="input-group">
            <label>
              Video / Podcast Summary <input type="text" />
            </label>{" "}
          </div>
          <div className="input-group">
            <label style={{ marginRight: "20px" }}>
              Is the Video / Podcast part of series?{" "}
              <select
                style={{ marginLeft: "20px" }}
                onChange={handleChange}
                name="seriesStatus"
              >
                <option value="yes">Yes</option>
                <option value="no">No (New Series)</option>
                <option value="one">One Off</option>
              </select>
            </label>{" "}
          </div>
          {existingSeries && (
            <div className="input-group">
              <label for="exampleDataList" className="form-label">
                Series Title
              </label>
              <input
                className="form-control"
                list="datalistOptions"
                style={{ marginLeft: "15px", width: "100px" }}
                placeholder="Type to search..."
              />
              <datalist id="datalistOptions">
                <option value="Series A" />
                <option value="Series B" />
                <option value="Series C" />
                <option value="Series D" />
                <option value="Series E" />
              </datalist>
            </div>
          )}
          {newSeries && (
            <div className="input-group">
              <label>
                Series Title
                <input type="text" />
              </label>{" "}
              <div className="input-group">
                <label>Series Synopsis</label>{" "}
                <textarea
                  rows="2"
                  style={{ width: "70%", marginLeft: "15px" }}
                />
              </div>
              <div className="input-group">
                <label>
                  Series Thumbnail{" "}
                  <input type="file" id="myfile" name="myfile" />
                </label>{" "}
              </div>
            </div>
          )}
          <div className="container" id="formButtonDiv">
            <button
              className="btn btn-danger"
              id="clearBtnForm"
              onClick={resetForm}
            >
              Clear
            </button>
            <button
              className="btn btn-success"
              id="submitBtnForm"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormTemp;
