import React from "react";
import "./footer.css";
import { Row, Col, Image } from "react-bootstrap";

import metaOr from "../media/icons/metaOr.png";
import logo_landing from "../media/logo/logo_landing.png";
import xOr from "../media/icons/x-or.png";
import lnOr from "../media/icons/lnOr.png";
import ytOr from "../media/icons/ytOr.png";
function Footer() {
  return (
    <div className="container-fluid" id="footerDiv">
      <Row id="linkCoverDiv">
        <Col id="footerCol">
          <h5
            style={{
              color: "white",
              fontSize: "25px",
              fontFamily: "Playfair Display",
            }}
          >
            About Us
          </h5>
          <a id="footerLink" href="/who-we-are">
            Who We Are
          </a>
          <a id="footerLink" href="/what-we-do">
            Practice Areas
          </a>
          <a id="footerLink" href="/what-we-do">
            Services
          </a>
          <a id="footerLink" href="/contact-us">
            Careers
          </a>
        </Col>
        <Col id="footerCol">
          <h5
            style={{
              color: "white",
              fontSize: "25px",
              fontFamily: "Playfair Display",
            }}
          >
            Connections
          </h5>
          <a id="footerLink" href="/e&k-connect/all-articles">
            Articles
          </a>
          <a id="footerLink" href="/e&k-connect/all-videos">
            Videos
          </a>
          <a id="footerLink" href="/e&k-connect/all-news">
            News
          </a>
          <a id="footerLink" href="/e&k-connect/publications">
            Publications
          </a>
        </Col>
        <Col id="footerCol">
          <h5
            style={{
              color: "white",
              fontSize: "25px",
              fontFamily: "Playfair Display",
            }}
          >
            Social Media
          </h5>{" "}
          <a id="footerLink" href="/">
            <Image
              style={{ width: "25px", marginRight: "9px" }}
              src={metaOr}
              alt="https://icons8.com/icon/BD98MqUP8eNf/meta"
            />
            Meta
          </a>{" "}
          <a id="footerLink" href="/">
            <Image
              style={{ width: "25px", marginRight: "9px" }}
              src={xOr}
              alt="https://icons8.com/icon/BD98MqUP8eNf/meta"
            />{" "}
            X
          </a>
          <a id="footerLink" href="/">
            <Image
              style={{ width: "25px", marginRight: "9px" }}
              src={ytOr}
              alt="https://icons8.com/icon/447/linkedin"
            />{" "}
            YouTube
          </a>
          <a id="footerLink" href="/">
            <Image
              style={{ width: "25px", marginRight: "9px" }}
              src={lnOr}
              alt="https://icons8.com/icon/447/linkedin"
            />
            LinkedIn
          </a>
        </Col>
        <Col id="footerCol">
          <h5
            style={{
              color: "white",
              fontSize: "25px",
              fontFamily: "Playfair Display",
            }}
          >
            Contact
          </h5>
          <p>
            {" "}
            Suite A33
            <br />
            Upperhill Garden Apartments
            <br /> Third Ngong Avenue
            <br /> P.O.Box 61435-00200 Nairobi
            <br /> Kenya
          </p>
        </Col>
      </Row>
      {/*      <Row>
        <Col sm={3}>
          <ul className="footerList">
            <li className="socialLi">
              {" "}
              <a href="/">
                <Image src={yt} alt="https://icons8.com/icon/447/linkedin" />
              </a>
            </li>
            <li className="socialLi">
              {" "}
              <a href="/">
                <Image src={ln} alt="https://icons8.com/icon/447/linkedin" />
              </a>
            </li>
            <li className="socialLi">
              <a href="/">
                <Image
                  src={meta}
                  alt="https://icons8.com/icon/118468/facebook"
                />
              </a>
            </li>
          </ul>
        </Col>
        <Col>
          {" "}
          <li className="footerLi">
            <a className="footerLink" href="/who-we-are">
              Contact Us
            </a>
          </li>
          <ul className="footerList">
            <li className="footerLi">
              <a className="footerLink" href="/platformX">
                Platform X
              </a>
            </li>
            <li className="footerLi">
              <a className="footerLink" href="/clients">
                Clients
              </a>
            </li>
            <li className="footerLi">
              <a className="footerLink" href="/who-we-are">
                Who we are
              </a>
            </li>
            <li className="footerLi">
              <a className="footerLink" href="/what-we-do">
                What we do
              </a>
            </li>
          </ul>
        </Col>
      </Row> */}
    </div>
  );
}

export default Footer;
