import React, { useState, useEffect } from "react";
import Who from "./who";
import Practice from "./practice";

import ClientDisplay from "../components/clientCar";
import {
  Card,
  Row,
  Col,
  Carousel,
  Image,
  Modal,
  Button,
} from "react-bootstrap";
import cur from "../media/images/cur.jpg";
import bond from "../media/images/bond.jpg";
import im from "../media/images/im.jpg";
import future from "../media/images/future.jpg";

import lead from "../media/images/lead.jpg";

import "./cover.css";
import { backendUrl } from "./api";
import { clientAll } from "./clientAll";
import { Link } from "react-router-dom";
import axios from "axios";

function Cover() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectArticle, setSelectArticle] = useState([]);
  const newsSub = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    Array.from(document.querySelector("input")).forEach(
      (event) => (event.target = "")
    );
  };
  const readArticle = (connectionTitle) => {
    axios
      .post(`${backendUrl}/readArticle.php`, {
        params: {
          title: connectionTitle,
        },
      })
      .then(function (response) {
        // Handle the response data
        setSelectArticle(response.data);
        window.open(`${backendUrl}/result.php?result=` + response.data);
      });
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [connectionCover, setConnectionCover] = useState([]);
  useEffect(() => {
    loadData();
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const loadData = () => {
    // Use Axios to fetch data
    axios
      .get(`${backendUrl}/connection.php`)
      .then((response) => {
        setConnectionCover(response.data);

        // Handle the fetched data
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className="container-fluid" id="coverDiv">
      <div className="container-fluid" id="landingCover">
        <Carousel
          class="coverCar"
          indicators={true}
          controls={false}
          pause={false}
        >
          <Carousel.Item id="cItem">
            <div
              className="container-fluid"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {" "}
              <Row>
                {" "}
                <Col id="carCoverCol" md={3}>
                  {" "}
                  <div className="container" id="carText">
                    <p id="landingText">
                      {" "}
                      Sustainable growth and impact
                      <h5>How our impact is felt</h5>
                    </p>
                    <Link to="/e&k-connect/all-news">
                      {" "}
                      <button id="landingBtn">news</button>
                    </Link>
                  </div>{" "}
                </Col>
                {windowWidth >= 768 ? (
                  <Col>
                    <div className="container">
                      {" "}
                      <Image fluid src={im} id="carImage" />
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </Carousel.Item>
          <Carousel.Item id="cItem" interval={3000}>
            <div
              className="container-fluid"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {" "}
              <Row>
                {" "}
                <Col md={3}>
                  {" "}
                  <div className="container" id="carText">
                    <p id="landingText">
                      {" "}
                      Shaping the future together
                      <h5>How we make an impact</h5>
                    </p>
                    <a href="/what-we-do">
                      <button id="landingBtn">what we do</button>
                    </a>
                  </div>{" "}
                </Col>
                {windowWidth >= 768 ? (
                  <Col>
                    <div className="container">
                      {" "}
                      <Image fluid src={future} id="carImage" />
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </Carousel.Item>

          <Carousel.Item id="cItem">
            <div
              className="container-fluid"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {" "}
              <Row>
                {" "}
                <Col md={3}>
                  {" "}
                  <div className="container" id="carText">
                    <p id="landingText">
                      {" "}
                      Globally competive Africa
                      <h5>The team leading the charge</h5>
                    </p>
                    <Link to="/who-we-are/#teamMemberDiv">
                      <button id="landingBtn">team</button>
                    </Link>
                  </div>{" "}
                </Col>
                {windowWidth >= 768 ? (
                  <Col>
                    <div className="container">
                      {" "}
                      <Image fluid src={lead} id="carImage" />
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </Carousel.Item>

          <Carousel.Item id="cItem">
            <div
              className="container-fluid"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {" "}
              <Row>
                {" "}
                <Col id="carCoverCol" md={3}>
                  {" "}
                  <div className="container-fluid" id="carText">
                    <p id="landingText">
                      {" "}
                      Leading with curiosity<h5>Where we make an impact</h5>
                    </p>
                    <Link to="/what-we-do/#whatPracticeDiv">
                      <button id="landingBtn">practice areas</button>
                    </Link>
                  </div>{" "}
                </Col>
                {windowWidth >= 768 ? (
                  <Col>
                    <div className="container">
                      {" "}
                      <Image fluid src={cur} id="carImage" />
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </Carousel.Item>
          <Carousel.Item id="cItem">
            <div
              className="container-fluid"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {" "}
              <Row>
                {" "}
                <Col md={3} style={{ padding: "10px" }}>
                  {" "}
                  <div className="container" id="carText">
                    <p id="landingText">
                      {" "}
                      We create bonds that last
                      <h5>Take the first steps</h5>
                    </p>{" "}
                    <a href="/contact-us">
                      <button id="landingBtn">Contact us</button>
                    </a>
                  </div>{" "}
                </Col>
                {windowWidth >= 768 ? (
                  <Col>
                    <div className="container">
                      {" "}
                      <Image fluid src={bond} id="carImage" />
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <Who />
      <Practice />
      <ClientDisplay data={clientAll} />
      <div className="container-fluid">
        <h4 style={{ marginTop: "20px", marginBottom: "30px" }}>CONNECTIONS</h4>
        <div className="container" id="connectHub">
          {connectionCover.slice(0, 3).map((connection, key) => (
            <Card
              id="connectCard"
              onClick={() => readArticle(connection.title)}
              as="a"
              target="blank"
              href={connection.body}
            >
              <Card.Header
                style={{
                  background: "inherit",
                  borderBottom: "1px solid #1AA99F",
                }}
              >
                <span>
                  <Image src={connection.logo} />{" "}
                  <p
                    style={{
                      textTransform: "uppercase",
                      margin: "auto",
                      fontSize: "25px",
                    }}
                  >
                    {connection.type}
                  </p>
                </span>
              </Card.Header>
              <Card.Body key={connection.title}>
                <Card.Title id="connectTitle">{connection.title}</Card.Title>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>

      <div ClassName="container" style={{ marginTop: "3em" }}>
        <h4 style={{ letterSpacing: "2px" }}>CONTACT</h4>
        <Row id="contactCoverRow">
          <Col sm={4}>
            <p id="contactText">
              {" "}
              Suite A33
              <br />
              Upperhill Garden Apartments
              <br /> Third Ngong Avenue
              <br /> P.O.Box 61435-00200 Upper-Hill Area
              <br /> Nairobi,
              <br /> Kenya
            </p>
            <p id="contactText">Subscribe to our newsletter</p>
            <form className="subForm" onSubmit={newsSub}>
              <div
                className="container-fluid"
                style={{ float: "left", padding: "0" }}
              >
                {" "}
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email address"
                  name="subEmail"
                />{" "}
                <button className="btn" id="subBtn" onClick={handleShow}>
                  Subscribe
                </button>
                <Modal show={show} centered onHide={handleClose}>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>Thank you for subscibing!</Modal.Body>
                  <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Changes
                  </Button> */}
                  </Modal.Footer>
                </Modal>
              </div>
            </form>
          </Col>

          <Col style={{ marginTop: "20px" }}>
            {" "}
            <iframe
              style={{ margin: "auto", width: "90%" }}
              id="locationMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8005193850745!2d36.80732107496569!3d-1.2942009986935046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10c24782632f%3A0xda4b632b3389ba81!2sUpperhill%20Gardens%20Apartments%2C%20Third%20Ngong%20Ave%2C%20Nairobi%2C%20Kenya!5e0!3m2!1sen!2suk!4v1702508271857!5m2!1sen!2suk"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </Col>
        </Row>
        <div
          className="container-fluid"
          style={{ display: "block", padding: "20px" }}
        >
          <Row>
            <Col md={8}>
              <h5 style={{ fontFamily: "Raleway", marginTop: "20px" }}>
                Want to start a new project with us? Let’s Start!
              </h5>{" "}
            </Col>
            <Col>
              <button className="btn" id="startBtn">
                Start Now
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Cover;
