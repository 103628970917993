import React, { useEffect, useState } from "react";
import { Card, Row, Col, Nav } from "react-bootstrap";
import axios from "axios";
import "./allVideos.css";
import { backendUrl } from "./api";
function AllVideos() {
  const [allVideoData, setAllVideoData] = useState([]);
  useEffect(() => {
    getVideos();
  }, []);

  function getVideos() {
    axios.get(`${backendUrl}/videos.php`).then(function (response) {
      setAllVideoData(response.data);
    });
  }
  return (
    <div className="container" id="allVideosDiv">
      <h4 style={{ letterSpacing: "2px", textTransform: "uppercase" }}>
        {" "}
        Videos
      </h4>{" "}
      {allVideoData.map((video, idx) => (
        <Card
          key={idx}
          id="videoCard"
          style={{ margin: "10px", borderRadius: "20px" }}
        >
          {" "}
          <Card.Body>
            <div className="container-fluid" id="allVideoRow">
              <Col>
                <iframe
                  id="allVideosThumb"
                  src={video.link}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                />{" "}
              </Col>
              <Col>
                <Card.Text style={{ marginTop: "30px" }}>
                  <h5 id="videoTitle">{video.title}</h5>
                  <h6 id="videoCat">{video.category}</h6>
                  <p id="videoDesc"> {video.videoDesc}</p>
                </Card.Text>
              </Col>
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default AllVideos;
