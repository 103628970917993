import React, { useState, useEffect } from "react";
import { Card, Col, Row, Image } from "react-bootstrap";
import "./allArticles.css";
import axios from "axios";
import energy from "../media/images/energy.png";
import desc from "../media/images/desc.png";
import { backendUrl } from "./api";
function Publication() {
  const [articles, setArticles] = useState([]);
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    getArticle();
  }, []);
  function getArticle() {
    axios.get(`${backendUrl}/publication.php`).then(function (response) {
      setArticles(response.data);

      /*     const articleFilter = connections.filter(
          (item) => item.type == "article"
        ); */
    });
  }
  return (
    <div className="container" id="allArticlesDiv">
      <Row>
        <h4
          style={{
            textTransform: "uppercase",
            letterSpacing: "2px",
            float: "left",
            marginBottom: "20px",
          }}
        >
          Publications
        </h4>
      </Row>

      <Row id="allConnectionsRow">
        {articles.map((article, idx) => (
          <a
            href={article.link}
            target="blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Card key={idx} id="allArticlesCard" as="a" href={article.body}>
              <Card.Body>
                <Card.Title id="allArticlesTitle">{article.title}</Card.Title>
              </Card.Body>
            </Card>
          </a>
        ))}
      </Row>
    </div>
  );
}

export default Publication;
