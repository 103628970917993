import React, { useEffect } from "react";
import "./what.css";

import { Card, Col, Row, Image, Accordion } from "react-bootstrap";
import sabra from "../media/images/sabra.jpg";
import res from "../media/icons/res.png";
import inno from "../media/icons/inno.png";
import strat from "../media/icons/strat.png";
import pm from "../media/icons/pm.png";
import adv from "../media/icons/adv.png";

import cap from "../media/icons/cap.png";
import { Fade } from "react-reveal";
import PracticeAreas from "./practiceAll";

function What() {
  return (
    <div
      className="container-fluid"
      id="whatDiv"
      style={{
        backgroundImage: "url(" + sabra + ")",
      }}
    >
      <div className="container-fluid" id="whatContentDiv">
        <div className="container">
          <h4 style={{ marginTop: "15px" }}>WHAT WE DO</h4>
          <div className="whoRow">
            <p style={{ textAlign: "left" }}>
              At the heart of our work is a commitment to transforming ideas
              into impactful solutions for our clients, partners, and
              communities; discover how we channel our curiosity into impact
              across our practice areas through our comprehensive range of
              services:
            </p>{" "}
            <Row id="whatSkillRow">
              <Col>
                <Card id="skillCard">
                  <Card.Body>
                    <Card.Title
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image src={cap} fluid id="skillImg" />
                    </Card.Title>
                    <Card.Text>
                      <h5 id="serviceTitle">Capital Placement</h5>
                      <br />
                      <p id="serviceP">
                        We offer a diverse array of services to investors as
                        they cultivate and enhance their portfolios,
                        encompassing investor deal sourcing and due diligence
                        through to investee value addition. Collaborating with
                        investors across the entire investment spectrum, we
                        specialize in incubating early-stage businesses,
                        fostering their growth, and building their capabilities.
                        Our approach is dedicated to nurturing a new generation
                        of transformative innovators poised to address urgent
                        global challenges.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>{" "}
              <Col>
                <Card id="skillCard">
                  <Card.Body>
                    <Card.Title
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image src={res} fluid id="skillImg" roundedCircle />
                    </Card.Title>
                    <Card.Text>
                      <h5 id="serviceTitle">Research and analysis</h5>
                      <br />
                      <p id="serviceP">
                        We employ a blend of qualitative and quantitative
                        research methodologies to derive comprehensive insights
                        that serve as valuable guidance for our clients
                        navigating various domains including market entry,
                        scale-up and more. We go beyond technical assistance,
                        offering knowledge management support and cultivating
                        impactful thought leadership, ensuring that our insights
                        not only inform but also inspire actionable strategies.
                        impact.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>{" "}
              <Col>
                <Card id="skillCard">
                  <Card.Body>
                    <Card.Title
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image src={inno} fluid id="skillImg" roundedCircle />
                    </Card.Title>
                    <Card.Text>
                      <h5 id="serviceTitle">Innovation Platform Support</h5>
                      <br />
                      <p id="serviceP">
                        Understanding that innovation is more than just an idea
                        – it&#39;s a journey that requires strategic support, we
                        empower organizations, entrepreneurs, and change-makers
                        at every stage of their innovation process. Our approach
                        allows our clients to access a collaborative ecosystem,
                        fostering connections between our clients, partners, and
                        communities, to support them in navigating the
                        intricacies of innovation and ensuring their readiness
                        for real world impact.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col>
                <Card id="skillCard">
                  <Card.Body>
                    <Card.Title
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image src={strat} id="skillImg" fluid />
                    </Card.Title>
                    <Card.Text>
                      <h5 id="serviceTitle">
                        Strategic Stakeholder Engagement Advisory
                      </h5>
                      <br />
                      <p id="serviceP">
                        Harnessing our cross-sector expertise and global
                        advisory capabilities, we equip our clients to not only
                        strategically negotiate diverse business landscapes, but
                        to navigate the intricate dynamics of different
                        stakeholders, ensuring their moves are not only informed
                        but also positioned for success.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card id="skillCard">
                  <Card.Body>
                    <Card.Title
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image src={pm} fluid id="skillImg" roundedCircle />
                    </Card.Title>
                    <Card.Text>
                      <h5 id="serviceTitle">
                        Project Management and Implementation
                      </h5>
                      <br />
                      <p id="serviceP">
                        Bold new approaches to pressing challenges often require
                        dedicated support in designing, operationalizing and
                        managing activities to generate lasting outcomes. We
                        collaborate with our clients and partners to co-design
                        initiatives, develop partnerships and operationalize
                        long-term developmental initiatives and projects that
                        are scalable and generate outsized impact.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>{" "}
              <Col>
                <Card id="skillCard">
                  <Card.Body>
                    <Card.Title
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image src={adv} fluid id="skillImg" roundedCircle />
                    </Card.Title>
                    <Card.Text>
                      <h5 id="serviceTitle">Strategy Advisory</h5>
                      <br />
                      <p id="serviceP">
                        In a dynamic global environment, we offer strategic
                        advisory services to guide our clients through evolving
                        complexities, crafting strategies that help them
                        navigate their most pressing challenges and empowering
                        them to maximize their impact.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div
        className="container"
        id="quoteRow"
        style={
          {
            // backgroundImage: "url(" + sabra + ")",
          }
        }
      >
        <div className="container-fluid" id="innerQuoteRow">
          {" "}
          <h3 className="quote" style={{ fontStyle: "italic" }}>
            "ولا جمال إلا جمال العمل"
          </h3>
          <p className="quoteEng">
            There is no beauty but the beauty of action
          </p>
          <p className="quoteLang">
            Arabic
            <br /> North African Proverb
          </p>
        </div>{" "}
      </div>
      <div className="container-fluid" id="whatPracticeDiv">
        <PracticeAreas />
      </div>{" "}
    </div>
  );
}

export default What;
