import React from "react";
import logoNav from "../media/logo/logoNav.png";
import { Form, Card, Row, Col, Image } from "react-bootstrap";
function Careers() {
  return (
    <div
      className="container-fluid"
      style={{ marginTop: "7em", height: "80vh" }}
    >
      <div className="container">
        {" "}
        <span>
          <Image src={logoNav} style={{ height: "auto" }} fluid />
        </span>{" "}
        <h1 STYLE={{ fontSize: "90px", marginTop: "6em" }}>COMING SOON</h1>
      </div>
    </div>
  );
}

export default Careers;
