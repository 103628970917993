import React from "react";

import "./who.css";
import TeamDisplay from "./teamCar";

import { TeamMembers } from "./teamMembers";
import { Button } from "react-bootstrap";

function Who() {
  return (
    <div className="container" id="whoDiv">
      <p id="coverWho">
        At E&amp;K, collaboration meets ingenuity in navigating the intricate
        tapestry of challenges that face our world today. With the needs of our
        clients, partners and communities at the heart of our work; and our
        roots deeply embedded in advising health sector leaders, our dynamic,
        multi-disciplinary team of seasoned strategists is fuelled by a passion
        for impact. Together, we leverage our global expertise, deep in-country
        experience and communities, to address some of the most intractable
        challenges of our time and work towards a world where shared prosperity
        is a reality; crafting bespoke solutions that not only address society’s
        evolving complexities, but also spark the transformative flames of
        collective action. Our expertise transcends borders having worked with
        over 100 clients in 20+ countries across the public, private, and
        development sectors in Africa, Europe, and beyond. Through extensive
        research, market studies, and strategic shaping, we don&#39;t just
        advise—we co-create pathways for progress and actively co-invest
        alongside our partners to realize our collective vision for the future.
      </p>
      <Button
        size="lg"
        style={{
          textTransform: "capitalize",
          float: "right",
          marginTop: "25px",
        }}
        id="connectBtn"
        href="/who-we-are"
        variant="outline"
      >
        Read More
      </Button>

      <div className="container" style={{ marginTop: "4em" }}>
        {" "}
        <h4 style={{ marginBottom: "30px", marginBottom: "30px" }}>
          MEET THE TEAM
        </h4>
        <TeamDisplay data={TeamMembers} />
        <Button
          size="lg"
          style={{
            textTransform: "capitalize",
            float: "right",
          }}
          id="connectBtn"
          href="/who-we-are"
          variant="outline"
        >
          Meet the Team
        </Button>
      </div>
    </div>
  );
}

export default Who;
